@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: "Plus Jakarta Sans", "Poppins", Arial, Helvetica, sans-serif;
}

body {
    box-sizing: border-box;
    margin: 0;
}

/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.puerto-header {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
        90deg,
        rgba(0, 194, 255, 1) 30%,
        rgba(255, 223, 55, 1) 70%
    );
}

/* svg 1 */

.custom-shape-divider-bottom-1685939409 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1685939409 svg {
    position: relative;
    display: block;
    width: calc(149% + 1.3px);
    height: 198px;
}

.custom-shape-divider-bottom-1685939409 .shape-fill {
    fill: #ffffff;
}

/* svg 2 */
.custom-shape-divider-top-1686037844 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1686037844 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 97px;
}

.custom-shape-divider-top-1686037844 .shape-fill {
    fill: #ffffff;
}

img {
    max-width: 100%;
}

a {
    all: inherit;
}

li {
    all: inherit;
}

.pckdisc {
    list-style-type: disc;
    padding-left: 1rem;
}

.custbold {
    font-weight: bold;
}
.bgStepper {
    margin-left: 2%;
    margin-right: 2%;
}
.rightalign {
    display: flex;
    justify-content: end;
}
.width100{
    width: 100% !important;
}
@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        --bs-modal-width: 90% !important;
    }
}
